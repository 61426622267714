<template>
  <div class="p-modal">
    <div class="p-container">
      <a @click="$emit('close')">close</a>
      <p>invite friends</p>
      <!-- Enter (one or multiple) email or mobile phone then run a server side function to email / text them a link to sign up -->
      <form @submit.prevent>
        Enter email: <input v-model.trim="friendEmail" />
        <button @click="inviteFriend()" :disabled="friendEmail == ''" class="button">send invitation</button>
      </form>
    </div>
  </div>
</template>

<script>
// import { commentsCollection, postsCollection, auth } from '@/firebase'
import { mapState } from 'vuex'

export default {
  // props: ['post'],
  data() {
    return {
      friendEmail: ''
    }
  },
  computed: {
    ...mapState(['userProfile'])
  },
  methods: {
    async inviteFriend() {
      // TODO:
      // - validate email format
      // - validate email not already in system
      // - call server-side function to send invite to email
      // - confirm success or failure to the user
      // - add mobile / text option
      // - allow bulk entry / multiple invitations
      console.log("Sending invitation to: " + this.friendEmail)
      this.$store.dispatch('logReferral', this.friendEmail)
      this.friendEmail = ''

      // close modal
      this.$emit('close')
    }
  }
}
</script>
