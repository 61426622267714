<template>
  <div class="c-modal">
    <div class="c-container">
      <a @click="$emit('close')">close</a>
      <p>search users</p>
      <!-- Add a scrolling list of all users that the current user is NOT already following or friends with -->
      <form @submit.prevent>
        <textarea v-model.trim="dummy_data"></textarea>
        <button @click="followUser()" :disabled="dummy_data == ''" class="button">follow user</button>
      </form>
    </div>
  </div>
</template>

<script>
// import { commentsCollection, postsCollection, auth } from '@/firebase'
import { mapState } from 'vuex'

export default {
  // props: ['post'],
  data() {
    return {
      dummy_data: ''
    }
  },
  computed: {
    ...mapState(['userProfile'])
  },
  methods: {
    async followUser() {
      console.log("Clicked button to follow user. Function not yet implemented.")
      // console.log("on post: " + this.post.id)
      // this.$store.dispatch('createComment', { post: this.post, comment: this.comment })
      // this.comment = ''

      // close modal
      this.$emit('close')
    }
  }
}
</script>
