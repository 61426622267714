<template>
  <div id="dashboard">
    <transition name="fade">
      <InviteFriendsModal v-if="showInviteFriendsModal" @close="toggleInviteFriendsModal()"></InviteFriendsModal>
    </transition>
    <section>
      <div class="col2">
        <div class="search-container">
          <h3>Friends</h3>
          <form @submit.prevent>
            <button @click="toggleInviteFriendsModal()" class="button">Invite friends</button>
          </form>
        </div>
        <div v-if="publicUsers.length">
          <div v-for="user in publicUsers" :key="user.id" class="listing">
            <div class="grid-container">
              <div class="grid-child">
                <h5>{{ user.name }}</h5>
              </div>
              <div class="grid-child">
                <button class="button">Message</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="no-results">You have no friends :(</p>
        </div>

        <!-- <h3>Following</h3>
        <div v-if="publicUsers.length">
          <div v-for="user in publicUsers" :key="user.id" class="listing">
            <div class="grid-container">
              <div class="grid-child">
                <h5>{{ user.name }}</h5>
              </div>
              <div class="grid-child">
                <button class="button">Send friend request</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="no-results">You are not following anyone yet :(</p>
        </div> -->

      </div>

      <!-- <div class="col1">
        <div class="profile">
          <div class="create-post">
            <h5>Invite</h5>
            <p>Invite your friends to join!</p>
            <form @submit.prevent>
              <button @click="toggleInviteFriendsModal()" class="button">Invite friends</button>
            </form>
          </div>
        </div>
      </div> -->

    </section>

    <!-- <transition name="fade">
      <div v-if="showInviteFriendsModal" class="p-modal">
        <div class="p-container">
          <a @click="closeInviteFriendsModal()" class="close">close</a>
          <div class="post"> 
            <h5>title</h5>
            <span>description</span>
          </div>
        </div>
      </div>
    </transition> -->

  </div>
</template>

<script>
// import { listingsCollection } from '@/firebase'
import { mapState } from 'vuex'
import SearchUsersModal from '@/components/SearchUsersModal'
import InviteFriendsModal from '@/components/InviteFriendsModal'

export default {
  components: {
    SearchUsersModal,
    InviteFriendsModal
    // AddListingModal,
    // ReportResultModal,
    // PlaceBidModal,

  },
  data() {
    return {
      showSearchUsersModal: false,
      showInviteFriendsModal: false
    }
  },
  computed: {
    ...mapState(['userProfile', 'publicUsers', 'friends', 'follows']),

    // filteredAndSortedListings: function () {
    //   let result = this.listings
    //   // Apply status filter first
    //   if (this.statusToggle) {
    //     result = result.filter(listing => listing.status == this.statusToggle)
    //   }
    //   // Apply search bar text filter
    //   if (this.search) {
    //     result = result.filter(listing => listing.Address.toLowerCase().includes(this.search.toLowerCase()))
    //   }
    //   // Apply other sort / filter options
    //   // TODO
    //   return result
    // }
  },
  methods: {

    // toggleSearchUsersModal() {
    //   this.showSearchUsersModal = !this.showSearchUsersModal
    //   console.log("SearchUsersModal toggled to " + this.showSearchUsersModal)
    // },
    // closeSearchUsersModal() {
    //   this.showSearchUsersModal = false
    // },
    toggleInviteFriendsModal() {
      this.showInviteFriendsModal = !this.showInviteFriendsModal
      console.log("InviteFriendsModal toggled to " + this.showInviteFriendsModal)
    },
    closeInviteFriendsModal() {
      this.showInviteFriendsModal = false
    }
  },
  filters: {
    trimLength(val) {
      if (val.length < 200) { return val }
      return `${val.substring(0, 200)}...`
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
